import * as queryString from "querystring"

export default $client => ({
  getJobs() {
    return $client.execute("get", "/api/retune/v1/job", null, () =>
      console.warn("Unable to get retune jobs"),
    )
  },
  createJob(job) {
    return $client.execute("post", "/api/retune/v1/job", job, () =>
      console.warn("Unable to create retune job"),
    )
  },
  activateJob(jobId) {
    return $client.execute("put", `/api/retune/v1/job/${jobId}`, null, () =>
      console.warn(`Unable to activate retune job with id: ${jobId}`),
    )
  },
  endOrCancelJob(jobId) {
    return $client.execute("delete", `/api/retune/v1/job/${jobId}`, null, () =>
      console.warn(`Unable to end/cancel retune job with id: ${jobId}`),
    )
  },
  getJobHistory(page, size) {
    return $client.execute("get", `/api/retune/v1/history/job?${queryString.stringify({page, size})}`, null, () =>
      console.warn("Unable to get retune job history"),
    )
  }
})
