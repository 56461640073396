<template>
  <b-form
    novalidate
    :validated="validated"
    @submit.prevent="onSubmit"
    @reset.prevent="onCancel">
    <b-row>
      <b-col cols="6">
        <div>
          <b-form-group
            label-cols="2"
            label="Name"
            label-class="pt-0"
            invalid-feedback="Name is required">
            <b-form-input
              v-model="block.name"
              name="name"
              required />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label="Description"
            label-class="pt-0">
            <b-form-textarea
              v-model="block.description"
              rows="3"
              max-rows="6"
              name="description" />
          </b-form-group>
          <b-button
            variant="outline-primary"
            class="ml-3"
            type="submit">
            Save
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-3"
            type="reset">
            Cancel
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label-cols="2"
          label="Country Codes"
          label-class="pt-0"
          description="A comma-separated list of two-letter country codes (US,MX,CA)">
          <b-form-textarea
            v-model="countryCodes"
            rows="15"
            max-rows="20"
            name="countryCodes" />
        </b-form-group>
        <b-button
          variant="outline-primary"
          @click="showImportWidget = true">
          Import
        </b-button>
        <b-button
          variant="outline-primary"
          class="ml-3"
          @click="exportCountryCodes()">
          Export
        </b-button>
        <b-form-group
          v-if="showImportWidget"
          :state="countrycodeFileInputState"
          class="mt-4"
          label="Upload a list of countrycodes"
          description="The file must contain a single column with the header 'COUNTRY CODE' and individual, two-letter country codes on each line, without commas"
          invalid-feedback="File must contain at least one valid country code">
          <b-form-file
            ref="countrycodeFileInput"
            v-model="countryCodeFile"
            required
            :state="countrycodeFileInputState"
            :disabled="uploadInProgress"
            accept=".csv, .xls, .xlsx" />
          <template
            v-if="uploadInProgress"
            v-slot:description>
            <b-spinner small />
            Loading file...
          </template>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Papa from "papaparse"

export default {
  name: "CountryCodeForm",
  props: {
    value: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        countries: [],
      })
    }
  },
  data: () => ({
    validated: false,
    importResults: null,
    showImportWidget: false,
    countryCodeFile: null,
    uploadInProgress: false,
    block: {
      name: "",
      description: "",
      countries: [],
    },
    countryCodes: "",
  }),
  computed: {
    countrycodeFileInputState() {
      if (!this.countryCodeFile || this.uploadInProgress) {
        return null
      }
      return this.countryCodes.length > 0
    },
  },
  watch: {
    countryCodeFile(newVal) {
      if (!newVal) {
        return
      }
      this.$loading.show()
      this.block.name = newVal.name
      if (newVal.name.includes(".csv")) {
        const parts = newVal.name.split(".csv")
        this.block.name = parts[0]
      }
      this.block.description = "CSV Import"
      this.uploadInProgress = true
      Papa.parse(newVal, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          this.importResults = results
          this.uploadInProgress = false
          this.showImportWidget = false
          this.countryCodes = results.data.map(row => row["COUNTRY CODE"]).join(",")
          this.$loading.hide()
        },
        error: () => this.uploadInProgress = false,
      })
    },
  },
  created() {
    this.block = this.value
    if (this.block.countries) {
      this.countryCodes = this.block.countries.join(",")
    }
  },
  methods: {
    async exportCountryCodes() {
      if (this.block.countries.length < 1) {
        this.$alert.warning("No country codes to export for this block.")
        return
      }
      let csvData = "COUNTRY CODE\n"
      this.block.countries.forEach((code) => {
        csvData += `${code}\n`
      })
      let testLink = document.createElement('a')
      testLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
      testLink.target = "_blank"
      let filename = "exported_country_codes"
      if (this.block.name.trim() !== "") {
        filename = this.block.name.trim()
      }
      testLink.download = `${filename}.csv`
      testLink.click()
    },
    onSubmit(evt) {
      this.validated = true

      const form = evt.target
      if (!form.checkValidity()) {
        this.$alert.warning("Country code block contains one or more errors. Please fix these errors before saving.")
        return
      }

      // Make sure we don't have any duplicates in the country code array.
      this.block.countries = [...new Set(this.countryCodes.split(","))]

      this.$emit("save", this.block)
    },
    onCancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>

</style>
