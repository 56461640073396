<template>
  <router-link
    :to="link"
    tag="p">
    <b-card
      v-if="visible"
      class="tool-card flex-column justify-content-center shadow"
      border-variant="primary"
      align="center">
      <b-icon
        :icon="iconName"
        width="32"
        height="32" />
      <p class="card-text mt-1">
        {{ name }}
      </p>
    </b-card>
  </router-link>
</template>

<script>
export default {
  name: "ToolCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      default: "tools",
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.tool-card {
  width: 9rem;
  height: 6rem;
  margin: 0.5rem;
  background-color: #f7f7f7;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tool-card:hover {
  transform: scale(1.1);
}

.card-text > p {
  color: black !important;
  text-decoration: none !important;
  font-style: normal !important;
}
</style>
