<template>
  <div>
    <h4 class="w-100 text-center my-3">
      Upcoming Jobs
    </h4>
    <scheduled-retune-jobs-table
      v-if="$asyncComputed.jobs.success"
      :jobs-provider-fn="getUpcomingJobs"
      @end-job="endJob" />
  </div>
</template>

<script>
import {DateTime} from "luxon"
import ScheduledRetuneJobsTable from "@/components/jobs/ScheduledRetuneJobsTable"
import {mapGetters} from "vuex"

export default {
  name: "UpcomingRetuneJobs",
  components: {
    ScheduledRetuneJobsTable,
  },
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
  },
  asyncComputed: {
    async jobs() {
      const jobs = await this.$api.jobs.getJobs()
      return jobs.jobs || []
    }
  },
  methods: {
    async getUpcomingJobs() {
      // Consider jobs as "upcoming" if their estimated start date is after 4am tomorrow.
      // If we're already past midnight, we won't jump ahead to the next day.
      let todayCutoffDate = DateTime.local()
      if (todayCutoffDate.hour > 4) {
        todayCutoffDate = todayCutoffDate.plus({days: 1})
      }
      todayCutoffDate = todayCutoffDate.set({hour: 4, minute: 0, second: 0})

      return this.jobs.filter(it => !it.active && todayCutoffDate <= DateTime.fromISO(it.estimatedStartTime))
    },
    async endJob(job) {
      if (!this.canRetuneEdit) {
        return
      }

      const message = `"Are you sure you wish to cancel this job?"`
      const modalConfirmed = await this.$confirm.open(message)
      if (!modalConfirmed) {
        return
      }

      await this.$loading.showWhile(async () => {
        await this.$api.jobs.endOrCancelJob(job.id)
        this.$alert.success(`Job cancelled successfully`)
        this.$asyncComputed.jobs.update()
      })
    }
  },
}
</script>

<style scoped>

</style>
