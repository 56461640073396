import Home from "@/views/Home.vue"
import Error from "@/views/Error.vue"
import Auth from "@/views/Auth"
import Sso from "@/views/Sso"
import Members from "@/views/members/Members"
import MembersCreate from "@/views/members/MembersCreate"
import MembersEdit from "@/views/members/MembersEdit"
import store from "@/store"
import UnsupportedBrowser from "@/views/UnsupportedBrowser"
import Retune from "@/views/retune/Retune"
import RetuneJobs from "@/views/retune/RetuneJobs"
import RetuneMarkets from "@/views/retune/markets/RetuneMarkets"
import MarketCreate from "@/views/retune/markets/MarketCreate"
import MarketEdit from "@/views/retune/markets/MarketEdit"
import RetuneBlocks from "@/views/retune/blocks/RetuneBlocks.vue"
import BlockCreate from "@/views/retune/blocks/BlockCreate.vue"
import BlockEdit from "@/views/retune/blocks/BlockEdit.vue"
import RetuneJobCreate from "@/views/retune/jobs/RetuneJobCreate"
import TodayRetuneJobs from "@/views/retune/jobs/TodayRetuneJobs"
import UpcomingRetuneJobs from "@/views/retune/jobs/UpcomingRetuneJobs"
import RetuneJobHistory from "@/views/retune/jobs/RetuneJobHistory"
import Esni from "@/views/Esni.vue"

// Because the Okta widget used in the login component requires a browser, we have to lazily initialize it for unit tests to work.
const Login = () => import("@/views/Login")

export default [
  {
    path: "/",
    name: "Root",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
  {
    path: "/unsupportedbrowser",
    name: "UnsupportedBrowser",
    component: UnsupportedBrowser,
  },
  {
    path: "/sso",
    name: "SSO",
    component: Sso,
  },
  {
    path: "/implicit/callback",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/members",
    name: "Members",
    component: Members,
    meta: {
      requiresAuth: true,
      requiresRoleFn: () => store.getters.canMembersView,
    },
  },
  {
    path: "/members/create",
    component: MembersCreate,
    meta: {
      requiresAuth: true,
      requiresRoleFn: () => store.getters.canMembersEdit,
    },
  },
  {
    path: "/members/edit/:login",
    name: "MembersEdit",
    component: MembersEdit,
    meta: {
      requiresAuth: true,
      requiresRoleFn: () => store.getters.canMembersEdit,
    },
  },
  {
    path: "/retune",
    component: Retune,
    children: [
      {
        path: "",
        redirect: "jobs",
      },
      {
        path: "jobs",
        component: RetuneJobs,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneView,
        },
        children: [
          {
            path: "",
            redirect: "today",
          },
          {
            path: "today",
            name: "TodayRetuneJobs",
            component: TodayRetuneJobs,
          },
          {
            path: "upcoming",
            name: "UpcomingRetuneJobs",
            component: UpcomingRetuneJobs,
          },
          {
            path: "history",
            name: "RetuneJobHistory",
            component: RetuneJobHistory,
          },
        ]
      },
      {
        path: "jobs/create",
        name: "RetuneJobCreate",
        component: RetuneJobCreate,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneEdit,
        },
      },
      {
        path: "markets",
        name: "RetuneMarkets",
        component: RetuneMarkets,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneView,
        },
      },
      {
        path: "markets/create",
        name: "RetuneMarketCreate",
        component: MarketCreate,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneEdit,
        },
      },
      {
        path: "markets/edit/:id",
        name: "RetuneMarketEdit",
        component: MarketEdit,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneEdit,
        },
      },
      {
        path: "blocks",
        name: "RetuneBlocks",
        component: RetuneBlocks,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneView,
        },
      },
      {
        path: "blocks/create",
        name: "RetuneBlockCreate",
        component: BlockCreate,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneEdit,
        },
      },
      {
        path: "blocks/edit/:id",
        name: "RetuneBlocksEdit",
        component: BlockEdit,
        meta: {
          requiresAuth: true,
          requiresRoleFn: () => store.getters.canRetuneEdit,
        },
      },
    ],
    meta: {
      requiresAuth: true,
      requiresRoleFn: () => store.getters.canRetuneView,
    },
  },
  {
    path: "/esni",
    component: Esni,
    meta: {
      requiresAuth: true,
      feature: "esni",
      requiresRoleFn: () => store.getters.canEsni,
    },
  },
]
