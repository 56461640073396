<template>
  <b-form
    novalidate
    :validated="validated"
    @submit.prevent="onSubmit"
    @reset.prevent="onCancel">
    <b-container>
      <b-row>
        <b-col>
          <b-row class="mb15">
            <b-col>
              <b>Retune Market</b>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="3"
                label="Name"
                label-class="pt-0">
                <b-form-input
                  v-model="marketRequest.name"
                  name="name" />
              </b-form-group>
              <b-form-group
                label-cols="3"
                label="Description"
                label-class="pt-0">
                <b-form-textarea
                  v-model="marketRequest.description"
                  rows="3"
                  max-rows="6"
                  name="description" />
              </b-form-group>
              <b-form-group
                label-cols="3"
                label="Type"
                label-class="pt-0">
                <b-form-radio-group
                  v-model="marketRequest.type"
                  :options="marketTypeOptions"
                  class="left-align"
                  name="market-type"
                  value="region" />
              </b-form-group>
              <b-form-group
                label-cols="3"
                label="Block">
                <model-select
                  v-model="marketRequest.zipCodeBlockId"
                  :options="blockOptions"
                  placeholder="-- Select the market zip or country code block --" />
              </b-form-group>
              <b-button
                variant="outline-primary"
                class="ml-3"
                type="submit">
                Save
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-3"
                type="reset">
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="mb15">
            <b-col>
              <b>Block Rules</b>
            </b-col>
          </b-row>
          <b-row
            class="lh-item">
            <b-col>
              {{ marketZipCodeBlockName }}
            </b-col>
          </b-row>
          <b-row v-if="marketRequest.zipCodeBlockId > 0">
            <b-col>
              <b-row
                v-for="rule in marketRequest.marketRules"
                :key="rule.zipCodeBlockId"
                class="lh-item">
                <b-col
                  cols="2"
                  class="left-align">
                  {{ rule.operation }}
                </b-col>
                <b-col
                  v-if="blocksById != {}"
                  cols="8">
                  {{ ruleBlockName(rule.zipCodeBlockId) }}
                </b-col>
                <b-col cols="2">
                  <b-button
                    variant="outline-primary"
                    @click="removeRule(rule)">
                    Remove
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="2"
                  class="left-align">
                  <b-form-radio-group
                    v-model="ruleType"
                    :options="ruleTypeOptions"
                    inline="false"
                    name="rule-type" />
                </b-col>
                <b-col cols="8">
                  <model-select
                    v-model="ruleZipCodeBlockId"
                    :options="ruleBlockOptions"
                    placeholder="-- Select the rule zip or country code block --" />
                </b-col>
                <b-col cols="2">
                  <b-button
                    v-if="ruleZipCodeBlockId > 0"
                    variant="outline-primary"
                    @click="addRule">
                    Add
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import { ModelSelect } from 'vue-search-select'

export default {
  name: "MarketForm",
  components: {
    ModelSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        type: "",
        zipCodeBlockId: 0,
        marketRules: [],
      })
    }
  },
  data: () => ({
    validated: false,
    marketTypeOptions:
      [
        { text: 'Team Market', value: 'region' },
        { text: 'Exclusion', value: 'exclusion' },
        { text: 'Overlap', value: 'overlap' },
      ],
    ruleType: "Plus",
    ruleTypeOptions: ['Intersect', 'Plus', 'Minus'],
    marketRequest: {},
    blocks: [],
    blocksById: {},
    ruleZipCodeBlockId: 0,
  }),
  computed: {
    blockOptions: {
      get() {
        // For editing and existing Market, filter out any blocks
        // that are already included in rules for this Market.
        // Create an excludeBlock object that is keyed by zip code block id
        // to contain these blocks to exclude
        let excludeBlocks = {}
        this.marketRequest.marketRules.forEach((rule) => {
          excludeBlocks[rule.zipCodeBlockId] = 1
        })
        let options = []
        this.blocks.forEach((block) => {
          // Only add blocks that are not excluded.
          if(!excludeBlocks[block.id]) {
            options.push({"value": block.id, "text": block.name})
          }
        })
        options.sort((a, b) => (a.name < b.name) ? -1 : 1)
        return options
      },
    },
    ruleBlockOptions: {
      get() {
        // Need to eliminate the primary zip code block and any blocks
        // included in existing rules.
        let excludeBlocks = {}
        excludeBlocks[this.marketRequest.zipCodeBlockId] = 1
        this.marketRequest.marketRules.forEach((rule) => {
          excludeBlocks[rule.zipCodeBlockId] = 1
        })
        let options = []

        this.blocks.forEach((block) => {
          // Only add blocks that are not excluded.
          if(!excludeBlocks[block.id]) {
            options.push({"value": block.id, "text": block.name})
          }
        })
        options.sort((a, b) => (a.name < b.name) ? -1 : 1)
        return options
      }
    },
    marketZipCodeBlockName: {
      get() {
        if (this.marketRequest.zipCodeBlockId && this.blocksById[this.marketRequest.zipCodeBlockId]) {
          return this.blocksById[this.marketRequest.zipCodeBlockId].name
        }
        return ""
      }
    },
  },
  created() {
    this.initMarketRequestObj()
    this.marketRequest = this.value
    this.$loading.showWhile(this.getZipCodeBlocks)
  },
  methods: {
    ruleBlockName(zipCodeBlockId) {
      let name = ""
      if (this.blocksById[zipCodeBlockId]) {
        name = this.blocksById[zipCodeBlockId].name
      }
      return name
    },
    initMarketRequestObj() {
      this.marketRequest = {
        id: 0,
        name: "",
        description: "",
        type: "region",
        zipCodeBlockId: 0,
        marketRules: [],
      }
    },
    async getZipCodeBlocks() {
      try {
        this.blocks = await this.$api.zipcodes.getZipCodeBlocks()
        this.blocksById = {}
        this.blocks.forEach((block) => {
          this.blocksById[block.id] = block
        })
      } catch (error) {
        console.error('Unable to retrieve zip code blocks.', error)
      }
    },
    addRule() {
      const rule = {
        marketId: this.marketRequest.id,
        operation: this.ruleType,
        zipCodeBlockId: this.ruleZipCodeBlockId,
      }
      this.marketRequest.marketRules.push(rule)
      this.ruleZipCodeBlockId = 0
    },
    removeRule(rule) {
      for (let i = 0; i < this.marketRequest.marketRules.length; i++) {
        if (rule.zipCodeBlockId === this.marketRequest.marketRules[i].zipCodeBlockId) {
          this.marketRequest.marketRules.splice(i, 1)
          break
        }
      }
    },
    onSubmit(evt) {
      this.validated = true
      const form = evt.target
      if (!form.checkValidity()) {
        this.$alert.warning("Market contains one or more errors. Please fix these errors before saving.")
        return
      }

      this.$emit("save", this.marketRequest)
    },
    onCancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>

.left-align {
  float: left;
}

.mb15 {
  margin-bottom: 15px;
}

.lh-item {
  height: 50px;
}

</style>
