<template>
  <b-container>
    <b-row class="text-center">
      <div class="hero">
        <h3 class="display-3">
          Unsupported Browser
        </h3>
        Your browser is not supported. Please use the latest version
        of Chrome, Firefox, or Safari to continue.
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "UnsupportedBrowser",
  data() {
    return {}
  },
}
</script>
