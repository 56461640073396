<template>
  <b-container class="mt-3">
    <div class="home mx-3">
      <div class="hero">
        <h1 class="display-3">
          MLB MAST Tools
        </h1>
      </div>
    </div>
    <b-row class="card-holder">
      <b-card-group
        deck4
        class="justify-content-center">
        <tool-card
          name="ReTune"
          icon-name="tv"
          :visible="canRetuneView"
          link="/retune" />
        <tool-card
          name="esni"
          icon-name="server"
          :visible="canEsni"
          link="/esni" />
        <!-- These last few "dummy" cards are a hack to center the card deck and still keep the last row left justified -->
        <p
          v-for="i in 5"
          :key="i"
          style="visibility: hidden">
          <b-card class="tool-card " />
        </p>
      </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex"
import ToolCard from "@/components/ToolCard"

export default {
  name: "Home",
  components: {
    ToolCard,
  },
  computed: {
    ...mapGetters([
      "authenticated",
      "canRetuneView",
      "canEsni"
    ]),
  },
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-card {
  width: 9rem;
  height: 6rem;
  margin: 0.5rem;
}

.card-holder {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 400;
}
</style>
