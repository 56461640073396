<template>
  <b-modal
    ref="loading"
    size="sm"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    content-class="loading-modal">
    <div class="text-center">
      <b-spinner
        variant="light"
        style="width: 4rem; height: 4rem;" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Loading",
  methods: {
    async showWhile(func) {
      const vm = this
      vm.show()
      return Promise.resolve(func()).finally(() => vm.hide())
    },
    show() {
      if (!this.$parent) {
        this.$mount()
        document.body.className = document.body.className.replace(
          "modal-open",
          "",
        )
        document.body.appendChild(this.$el)
      }

      this.$refs.loading.show()
    },
    hide() {
      this.$refs.loading.hide()
    },
  },
}
</script>

<style>
.loading-modal {
  background: none;
  border: none;
}
</style>
