export function roles(rootGetters) {
  return (rootGetters.roles && rootGetters.roles) || []
}

export function hasAdminRole(rootGetters) {
  return roles(rootGetters).includes("admin")
}

export function allowedRoles(rootGetters, allowed) {
  if (hasAdminRole(rootGetters)) {
    return true
  } else {
    let a = new Set(roles(rootGetters))
    let b = new Set(allowed)
    let intersection = new Set([...a].filter(x => b.has(x)))
    return intersection.size > 0
  }
}

export default {
  getters: {
    isAdmin(state, getters, rootState, rootGetters) {
      return hasAdminRole(rootGetters)
    },
    notAdmin(state, getters, rootState, rootGetters) {
      return !hasAdminRole(rootGetters)
    },
    canMembersView(state, getters, rootState, rootGetters) {
      return allowedRoles(rootGetters, [
        "members-view", "members-edit",
      ])
    },
    canMembersEdit(state, getters, rootState, rootGetters) {
      return allowedRoles(rootGetters, [
        "members-edit",
      ])
    },
    canRetuneView(state, getters, rootState, rootGetters) {
      return allowedRoles(rootGetters, [
        "retune-view", "retune-edit",
      ])
    },
    canRetuneEdit(state, getters, rootState, rootGetters) {
      return allowedRoles(rootGetters, [
        "retune-edit",
      ])
    },
    canEsni(state, getters, rootState, rootGetters) {
      return allowedRoles(rootGetters, ['esni-view'])
    },
  },
}
