<template>
  <div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalResults"
      :per-page="perPage"
      aria-controls="job-history-table" />
    <b-table
      id="job-history-table"
      :items="getJobHistory"
      :fields="fields"
      no-provider-filtering
      no-provider-sorting
      :per-page="perPage"
      :current-page="currentPage"
      show-empty>
      <template #empty>
        <div class="font-italic text-muted text-center w-100">
          No job history found
        </div>
      </template>
      <template #table-busy>
        <div class="font-italic text-muted text-center w-100">
          <b-spinner
            variant="secondary"
            small />
          Loading...
        </div>
      </template>
      <template #cell(type)="{ value }">
        <template v-if="value === 'B'">
          Market 1 (alt channel)
        </template>
        <template v-else-if="value === 'C'">
          Market 2 (slate)
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template #cell(startTime)="{ value }">
        {{ formatDate(value) }}
      </template>
      <template #cell(endTime)="{ value }">
        {{ formatDate(value) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import {DateTime} from "luxon"

export default {
  name: "RetuneJobHistoryTable",
  data: () => ({
    totalResults: 0,
    perPage: 25,
    currentPage: 1,
    fields: [
      {key: 'marketName', label: 'Retune Market'},
      {key: 'type', label: 'Type'},
      {key: 'status', label: 'Status'},
      {key: 'startTime', label: 'Start Time'},
      {key: 'endTime', label: 'End Time'},
    ]
  }),
  methods: {
    async getJobHistory({ currentPage, perPage }) {
      const results = await this.$api.jobs.getJobHistory(currentPage, perPage)
      this.totalResults = results.totalResults
      return results.results
    },
    formatDate(date) {
      if (!date) {
        return ""
      }
      return DateTime.fromISO(date).toLocal().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    }
  }
}
</script>

<style scoped>

</style>
