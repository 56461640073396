<template>
  <b-form
    novalidate
    :validated="validated"
    @submit.prevent="onSubmit"
    @reset.prevent="onCancel">
    <b-form-row>
      <b-col md>
        <b-form-group
          label="Estimated Start Date"
          invalid-feedback="Estimated Start Date is required">
          <b-form-datepicker
            v-model="formStartDate"
            :min="new Date()"
            :state="dateIsValid"
            required />
        </b-form-group>
      </b-col>
      <b-col md>
        <b-form-group
          :label="`Estimated Start Time (${localTimeZoneName})`"
          invalid-feedback="Estimated Start Time is required">
          <b-form-timepicker
            v-model="formStartTime"
            :state="timeIsValid"
            required />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md>
        <b-form-group
          label="Retune Market"
          :state="marketIsValid"
          invalid-feedback="Retune Market is required">
          <model-select
            v-model="marketId"
            :options="marketOptions"
            :is-error="marketIsValid === false"
            placeholder="-- Select a market --" />
        </b-form-group>
      </b-col>
      <b-col md>
        <b-form-group label="Type">
          <b-select
            v-model="type"
            required>
            <b-select-option value="B">
              Market 1 (alt channel)
            </b-select-option>
            <b-select-option value="C">
              Market 2 (slate)
            </b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <div class="d-flex justify-content-between">
      <b-btn
        variant="outline-secondary"
        type="reset">
        Cancel
      </b-btn>
      <b-btn
        variant="outline-primary"
        type="submit">
        Save
      </b-btn>
    </div>
  </b-form>
</template>

<script>
import {DateTime} from "luxon"
import {mapState} from "vuex"
import {ModelSelect} from 'vue-search-select'

export default {
  name: "JobForm",
  components: {
    ModelSelect,
  },
  data: () => ({
    validated: false,
    marketId: null,
    type: "B",
    formStartDate: "",
    formStartTime: "",
  }),
  computed: {
    ...mapState("retune", [
      "markets"
    ]),
    marketOptions() {
      return this.markets
        .map(it => ({
          value: it.id,
          text: it.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    dateIsValid() {
      return !this.validated ? null : !!this.formStartDate
    },
    timeIsValid() {
      return !this.validated ? null : !!this.formStartTime
    },
    localTimeZoneName() {
      return DateTime.local().offsetNameShort
    },
    marketIsValid() {
      return !this.validated ? null : !!this.marketId
    }
  },
  methods: {
    onSubmit(evt) {
      this.validated = true

      const form = evt.target
      if (!form.checkValidity() || !this.dateIsValid || !this.timeIsValid || !this.marketIsValid) {
        this.$alert.warning("Job contains one or more errors. Please fix these errors before saving.")
        return
      }

      const estimatedStartTime = DateTime.fromISO(`${this.formStartDate}T${this.formStartTime}`)

      const job = {
        marketId: this.marketId,
        type: this.type,
        estimatedStartTime
      }

      this.$emit("save", job)
    },
    onCancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>

</style>
