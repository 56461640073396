<template>
  <b-container>
    <b-row class="text-center">
      <b-col v-if="show">
        <div class="logo">
          <h2>{{ errors[errorKey].title }}</h2>
        </div>
        <br>

        <p
          v-if="errorText"
          class="lead">
          {{ errorText }}
        </p>
        <p
          v-else
          class="lead">
          {{ errors[errorKey].body }}
        </p>

        <p
          v-if="errors[errorKey].link"
          class="lead text-muted">
          <router-link :to="errors[errorKey].link">
            {{ errors[errorKey].linkText }}
          </router-link>
        </p>

        <div
          v-if="errors[errorKey].contactPrompt"
          class="lead text-muted">
          {{ errors[errorKey].contactPrompt }}
        </div>
        <div
          v-for="(contact, index) in errors[errorKey].contactAddress"
          :key="index">
          <b-btn
            :href="'mailto:' + contact"
            variant="link"
            class="p-0"
            size="lg">
            {{ contact }}
          </b-btn>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Error",
  data() {
    const contactEmail = "tom.amedro@mlb.com"
    return {
      show: false,
      errorKey: this.$route.query.type || "unknown",
      errorText: this.$route.query.errorText,
      errors: {
        403: {
          title: "403",
          body: "Oops, an error has occurred. Forbidden.",
          link: "/",
          linkText: "Return Home",
          contactPrompt: "If the problem persists, reach out to:",
          contactAddress: [contactEmail],
        },
        account: {
          title: "Welcome to Tools Template",
          body:
            "You have no Tools currently assigned to your account. Reach out to the following to request access.",
          contactAddress: [contactEmail],
        },
        unknown: {
          title: "Error",
          body: "Oops, an error has occurred.",
          link: "/",
          linkText: "Return Home",
          contactPrompt: "If the problem persists, reach out to:",
          contactAddress: [contactEmail],
        },
      },
    }
  },
}
</script>
