import Vue from 'vue'

const isIEOrEdge = () => {
  if (/MSIE 10/i.test(navigator.userAgent)) {
    // This is internet explorer 10
    console.log("IE 10 is unsupported")
    return true
  }

  if (
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent)
  ) {
    // This is internet explorer 9 or 11
    console.log("IE 9/11 is unsupported")
    return true
  }

  if (/Edge\/\d./i.test(navigator.userAgent)) {
    // This is Microsoft Edge
    console.log("Microsoft Edge is unsupported")
    return true
  }

  return false
}

export default class RouterAuthGuard {
  constructor(store) {
    this.store = store
  }

  onAuthRequired = async (to, from, next) => {
    if (isIEOrEdge() && to.path !== "/unsupportedbrowser") {
      // Currently IE and Edge are not supported
      next({ path: "/unsupportedbrowser" })
      return
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Check to see if user is not logged in and should be.
      if (!await Vue.prototype.$auth.isAuthenticated()) {
        next({ path: "/login" })
        return
      }
    }

    if (this.store.getters.isAdmin) {
      // Skip the role checks if the user is an admin.
      next()
      return
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      // A non-admin is trying to access an admin page.
      next({ path: "/error?type=403" })
      return
    }

    if (to.matched.some(record => record.meta.requiresRoleFn && !record.meta.requiresRoleFn())) {
      // The user doesn't have sufficient roles to view the content.
      next({ path: "/error?type=403" })
      return
    }

    next()
  }
}
