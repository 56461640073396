<template>
  <b-overlay
    class="position-absolute h-100 w-100"
    :show="showLoadingSpinner"
    spinner-variant="primary"
    rounded="lg">
    <b-container>
      <tool-header tool-name="ESNI Service Tool" />
      <div class="mb-3 ml-2">
        <b-input-group
          prepend="GamePK"
          class="w-50">
          <b-form-input
            v-model.number="gamePk"
            required
            @click.prevent="resetSearch"
            @keydown.delete="noFeedsExist = true"
            @keydown.enter="getFeeds" />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click.prevent="getFeeds">
              Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <b-container v-if="!noFeedsExist">
        <h1>NO FEEDS FOR {{ gamePk }}</h1>
      </b-container>
      <b-container v-if="gamePkIntErr">
        <h1>GAMEPK MUST BE AN INTEGER</h1>
      </b-container>
      <b-container
        v-if="renderGamePkResults"
        class="p-0">
        <b-card
          v-for="(feed, i) in feeds"
          :key="`${feed.id}-${i}`"
          class="m-2"
          no-body>
          <b-card-header class="p-2 d-flex">
            <div
              v-b-toggle="`${feed.id}`"
              class="pl-1 w-100 d-flex justify-content-between align-items-center"
              @click.prevent="pkgOpen = pkgOpen === i ? null : i">
              <strong>FEED: {{ feed.id }}</strong>
              <b-icon
                :icon="i === pkgOpen ? `chevron-down` : `chevron-right`"
                class="align-self-center ml-auto"
                variant="secondary"
                scale="1.2" />
            </div>
          </b-card-header>
          <b-collapse :id="`${feed.id}`">
            <b-container>
              <p>Media Type: <strong>{{ feed.mediaType }}</strong></p>
              <p>Media Feed Type: <strong>{{ feed.mediaFeedType }}</strong></p>
              <p>Media Feed SubType: <strong>{{ feed.mediaFeedSubType }}</strong></p>
              <p>Call Letters: <strong>{{ feed.callLetters }}</strong></p>
              <p>Is Blacked Out: <strong>{{ feed.IsBlackedOut }}</strong></p>
              <p>Has Subscription: <strong>{{ feed.hasSubscription }}</strong></p>
              <p>Blackout Code: <strong>{{ feed.blackoutCode }}</strong></p>
              <p>Blackout Reason: <strong>{{ feed.blackoutReason }}</strong></p>
              <p>Entitlement Code: <strong>{{ feed.entitlementCode }}</strong></p>
              <p>Entitlement Reason: <strong>{{ feed.entitlementReason }}</strong></p>
            </b-container>
            <b-card>
              <b-button
                v-b-toggle="`${feed.id}-${i}-viewingpolicy`"
                class="mb-2"
                variant="primary">
                Viewing Policies
              </b-button>
              <b-collapse :id="`${feed.id}-${i}-viewingpolicy`">
                <b-card
                  v-for="(vp, j) in feed.viewingPolicies"
                  :key="`${vp.Href}-${j}`">
                  <p>Href: <strong>{{ vp.Href }}</strong></p>
                  <p>Action: <strong>{{ vp.Action }}</strong></p>
                  <p>Action Label: <strong>{{ vp.ActionLabel }}</strong></p>
                  <p>Audience ID: <strong>{{ vp.AudienceID }}</strong></p>
                  <b-button
                    v-b-toggle="`${feed.id}-${j}-audience`"
                    class="mb-2 mt-2"
                    variant="secondary">
                    Audience
                  </b-button>
                  <b-collapse :id="`${feed.id}-${j}-audience`">
                    <b-card>
                      <p>Href: <strong>{{ vp.Audience.Href }}</strong></p>
                      <p>Match: <strong>{{ vp.Audience.Match }}</strong></p>
                      <p>Type: <strong>{{ vp.Audience.Type }}</strong></p>
                      <p>Authenticated: <strong>{{ vp.Audience.Authenticated }}</strong></p>

                      <b-card
                        v-for="(child, k) in vp.Audience.Children"
                        :key="`${child.Href}-${k}`">
                        <p>ID: <strong>{{ child.ID }}</strong></p>
                        <p>Href: <strong>{{ child.Href }}</strong></p>
                        <p>Match: <strong>{{ child.Match }}</strong></p>
                        <p>Type: <strong>{{ child.Type }}</strong></p>
                        <p>Authenticated: <strong>{{ child.Authenticated }}</strong></p>
                      </b-card>
                    </b-card>
                  </b-collapse>
                </b-card>
              </b-collapse>
            </b-card>
          </b-collapse>
        </b-card>
      </b-container>
    </b-container>
  </b-overlay>
</template>
<script>
import ToolHeader from "@/components/ToolHeader.vue"

export default {
  name: 'Esni',
  components: {ToolHeader},
  data: () => ({
    showLoadingSpinner: false,
    pkgOpen: null,
    renderGamePkResults: false,
    gamePk: null,
    noFeedsExist: true,
    gamePkIntErr: false,
    feeds: [],
  }),
  methods: {
    async getFeeds() {
      try {
        this.renderGamePkResults = !this.renderGamePkResults
        if(!Number.isInteger(this.gamePk)) {
          this.noFeedsExist = true
          this.gamePkIntErr = true
          return
        }
        this.noFeedsExist = true
        this.showLoadingSpinner = true
        const val = await this.$api.esni.getFeeds(this.gamePk)
        const {feeds} = val[0]

        if (feeds !== null) {
          let feedViewingPolicyXrefs = ''
          feeds.map(feed => {
            feedViewingPolicyXrefs += `${feed.feedXref},`
          })
          feedViewingPolicyXrefs = feedViewingPolicyXrefs.slice(0,-1) // remove ending comma
          const vps = await this.$api.esni.getViewingPolicies(feedViewingPolicyXrefs)
          const feedAndVPs = feeds.map(async feed => {
            feed.viewingPolicies = vps[feed.feedXref]

              for (const vp of feed.viewingPolicies) {
                const a = await this.$api.esni.getAudienceByIds(vp.AudienceID)
                if (a[vp.AudienceID].AudienceModel) {
                  vp.Audience = a[vp.AudienceID].AudienceModel
                } else {
                  vp.Audience = a[vp.AudienceID]
                }
              }
            return feed
          })

          this.feeds = await Promise.all(feedAndVPs)
          this.renderGamePkResults = true
          this.showLoadingSpinner = false
        } else {
          this.showLoadingSpinner = false
          this.noFeedsExist = false
        }
      } catch (err) {
        this.renderGamePkResults = false
        this.showLoadingSpinner = false
      }
    },
    resetSearch() {
      this.gamePk = null
      this.gamePkIntErr = false
      this.showLoadingSpinner = false
      this.noFeedsExist = true
      this.feeds = []
    }
  },
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}

</style>
