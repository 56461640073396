<template>
  <div>
    <p class="text-muted">
      Use to determine the feed a given zip code would receive at the current moment, based upon any currently active jobs.
      <br>
      <br>
      Note that, due to caching, it may take up to 5 minutes for results affected by taken or ended jobs to go into effect.
    </p>
    <b-form-group label="Zip Code">
      <b-input
        v-model="zipCode"
        required
        @keydown.enter.native="performRetuneLookup" />
    </b-form-group>
    <b-form-group label="Country Code">
      <b-input
        v-model="country"
        @keydown.enter.native="performRetuneLookup" />
    </b-form-group>
    <b-btn
      variant="primary"
      :disabled="!zipCode"
      @click="performRetuneLookup">
      Lookup
    </b-btn>
    <template v-if="lookupResult !== null">
      <hr>
      <p>
        Zip code <strong>{{ searchedZipCode }}</strong>
        IN
        Country <strong>{{ searchedCountry }}</strong>
        would receive the
        <strong v-if="lookupResult.feedCode === 'A'">
          Primary
        </strong>
        <strong v-else-if="lookupResult.feedCode === 'B'">
          Retune Market 1 (alt channel)
        </strong>
        <strong v-else-if="lookupResult.feedCode === 'C'">
          Retune Market 2 (slate)
        </strong>
        <strong v-else>
          {{ lookupResult.feedCode }}
        </strong>
        feed.
      </p>
      <template v-if="lookupResult.reason">
        Reason:
        <blockquote>{{ lookupResult.reason }}</blockquote>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "RetuneZipCodeLookup",
  data: () => ({
    zipCode: "",
    country: "US",
    searchedZipCode: "",
    searchedCountry: "",
    lookupResult: null
  }),
  methods: {
    async performRetuneLookup() {
      if (!this.zipCode) {
        return
      }
      this.lookupResult = null
      this.searchedZipCode = this.zipCode
      this.searchedCountry = this.country

      try {
        this.lookupResult = await this.$api.feeds.lookupFeedForZipCode(this.zipCode, this.country)
      } catch (e) {
        this.$alert.error("Failed to perform lookup, please try again.")
      }
    }
  }
}
</script>

<style scoped>

</style>
