<template>
  <div id="app">
    <navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar"
import { mapMutations } from "vuex"

export default {
  name: "App",
  components: {
    Navbar,
  },
  async created() {
    // Alert handlers
    this.$bus.$on("send-alert", $event => {
      console.log("Show alert", $event)

      const toastSettings = {
        variant: $event.variant,
        autoHideDelay: $event.variant === "success" ? 2000 : 5000,
        appendToast: true,
        solid: true,
        noCloseButton: true,
        toaster: "b-toaster-top-center",
      }

      this.$bvToast.toast($event.text, toastSettings)
    })
  },
  methods: {
    ...mapMutations({
      sessionLogout: "logout",
    }),
  },
}
</script>

<style>

#app {
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero .display-3 {
  font-weight: 500;
  font-size: 2rem;
}

.table > thead > tr > th {
  vertical-align: middle;
}

.xl-wide {
  padding: 0.2rem 2rem;
}

/*Used to style the vue-search-select drop-down similarly to the bootstrap <b-select> component*/
.ui.selection.dropdown > .text {
  color: #495057 !important;
}

.ui.selection.dropdown.error {
  border-color: #dc3545 !important;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.ui.selection.dropdown.error.active {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.form-group.is-valid .ui.selection.dropdown {
  border-color: #28a745 !important;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-group.is-valid .ui.selection.dropdown.active {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
</style>
