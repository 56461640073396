<template>
  <div>
    <div class="d-flex w-100 justify-content-md-end">
      <b-btn
        v-b-modal.zip-code-lookup
        variant="outline-primary">
        Zip Code Lookup
      </b-btn>
    </div>
    <h4 class="w-100 text-center">
      Active Jobs
    </h4>
    <active-retune-jobs
      v-if="$asyncComputed.jobs.success"
      :jobs-provider-fn="getActiveJobs"
      @end-job="endJob" />
    <hr>
    <h4 class="w-100 text-center mt-5 mb-4">
      Scheduled Jobs
    </h4>
    <scheduled-retune-jobs-table
      v-if="$asyncComputed.jobs.success"
      :jobs-provider-fn="getScheduledJobs"
      enable-take
      @activate-job="activateJob"
      @end-job="endJob" />
    <b-modal
      id="zip-code-lookup"
      title="Zip Code Lookup"
      hide-footer>
      <retune-zip-code-lookup />
    </b-modal>
  </div>
</template>

<script>
import ActiveRetuneJobs from "@/components/jobs/ActiveRetuneJobs"
import {DateTime} from "luxon"
import ScheduledRetuneJobsTable from "@/components/jobs/ScheduledRetuneJobsTable"
import RetuneZipCodeLookup from "@/components/jobs/RetuneZipCodeLookup"
import {mapGetters} from "vuex"

export default {
  name: "TodayRetuneJobs",
  components: {
    RetuneZipCodeLookup,
    ScheduledRetuneJobsTable,
    ActiveRetuneJobs,
  },
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
  },
  asyncComputed: {
    async jobs() {
      const jobs = await this.$api.jobs.getJobs()
      return jobs.jobs || []
    }
  },
  methods: {
    async getActiveJobs() {
      return this.jobs.filter(it => it.active)
    },
    async getScheduledJobs() {
      // Consider jobs as "today" if their estimated start date is before 4am tomorrow.
      // If we're already past midnight, we won't jump ahead to the next day.
      let todayCutoffDate = DateTime.local()
      if (todayCutoffDate.hour > 4) {
        todayCutoffDate = todayCutoffDate.plus({days: 1})
      }
      todayCutoffDate = todayCutoffDate.set({hour: 4, minute: 0, second: 0})

      return this.jobs.filter(it => !it.active && todayCutoffDate > DateTime.fromISO(it.estimatedStartTime))
    },
    async activateJob(job) {
      if (!this.canRetuneEdit) {
        return
      }

      if (job.active) {
        return // We can't activate an already active job!
      }

      const modalConfirmed = await this.$confirm.open("Are you sure you wish to take this job?")
      if (!modalConfirmed) {
        return
      }

      await this.$loading.showWhile(async () => {
        await this.$api.jobs.activateJob(job.id)
        this.$alert.success(`Job successfully taken`)
        this.$asyncComputed.jobs.update()
      })
    },
    async endJob(job) {
      if (!this.canRetuneEdit) {
        return
      }

      const message = `Are you sure you wish to ${job.active ? 'end' : 'cancel'} this job?`
      const modalConfirmed = await this.$confirm.open(message)
      if (!modalConfirmed) {
        return
      }

      await this.$loading.showWhile(async () => {
        await this.$api.jobs.endOrCancelJob(job.id)
        this.$alert.success(`Job ${job.active ? 'ended' : 'cancelled'} successfully`)
        this.$asyncComputed.jobs.update()
      })
    }
  },
}
</script>

<style scoped>

</style>
