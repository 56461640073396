<template>
  <b-container>
    <tool-header tool-name="Member Manager">
      <b-form-group>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            placeholder="Type to Search" />
          <b-input-group-append>
            <b-btn
              :disabled="!filter"
              variant="search"
              @click="filter = ''">
              Clear
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </tool-header>
    <b-row
      align-h="end"
      class="mt-1">
      <b-col>
        <router-link to="/members/create">
          <b-btn
            :disabled="!canMembersEdit"
            class="float-right xl-wide"
            variant="primary">
            Add Member
          </b-btn>
        </router-link>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-table
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :items="members"
          :fields="fields">
          <template v-slot:cell(login)="{item}">
            {{ item.login }}
            <br>
            <b-badge
              v-if="item.roles && (item.roles.includes('members-edit') || item.roles.includes('admin'))"
              pill
              variant="dark">
              Super User
            </b-badge>
          </template>
          <template
            v-if="canMembersEdit"
            v-slot:cell(actions)="{item}">
            <router-link :to="{path: `/members/edit/${item.login}`}">
              <b-btn
                variant="link"
                class="mr-1">
                Edit
              </b-btn>
            </router-link>
            <b-btn
              variant="link"
              @click.prevent="onDeleteMember(item.login)">
              Delete
            </b-btn>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ToolHeader from '@/components/ToolHeader'

  export default {
    components: {
      ToolHeader,
    },
    data() {
      return {
        members: [],
        sortBy: 'login',
        sortDesc: false,
        filter: null,
        fields: [
          { key: 'login', sortable: true },
          { key: 'firstName', sortable: true },
          { key: 'lastName', sortable: true },
          { key: 'actions', label: 'Actions' },
        ],
      }
    },
    computed: {
      ...mapGetters([
        'canMembersEdit',
      ]),
    },
    async created() {
      await this.$loading.showWhile(this.refreshMembers)
    },
    methods: {
      async refreshMembers() {
        this.members = await this.$api.members.getMembers()
      },
      async onDeleteMember(login) {
        this.$confirm.open(`Are you sure you want to delete the member "${login}"?`)
          .then(res => {
            if (res) {
              this.$api.members.deleteMember(login).then(() => this.$loading.showWhile(this.refreshMembers))
            }
          })
      },
    },
  }
</script>
