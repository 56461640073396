<template>
  <b-container
    v-if="marketRequest"
    class="pb-3">
    <h2 class="my-3">
      Edit Market
    </h2>
    <market-form
      :value="marketRequest"
      @save="onSave"
      @cancel="onCancel" />
  </b-container>
</template>

<script>
import MarketForm from "@/components/markets/MarketForm"
import {mapActions} from "vuex"

export default {
  name: "MarketEdit",
  components: {
    MarketForm,
  },
  data: () => ({
    marketId: null,
    marketRequest: null
  }),
  async created() {
    await this.$loading.showWhile(async () => {
      const marketId = parseInt(this.$route.params.id)
      const marketRequest = await this.$api.markets.getMarket(marketId)
      if (marketRequest) {
        this.marketId = marketId
        this.marketRequest = marketRequest
      } else {
        this.$alert.warning(`Market with ID [${marketId}] not found`)
        this.$router.push("/retune/markets")
      }
    })
  },
  methods: {
    ...mapActions("retune", [
      "loadMarkets",
    ]),
    async onSave(marketRequest) {
      await this.$loading.showWhile(async () => {
        marketRequest.id = this.marketId
        await this.$api.markets.updateMarket(marketRequest)
        await this.loadMarkets()
        this.$alert.success("Market updated successfully")
        this.$router.push("/retune/markets")
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
}
</script>
