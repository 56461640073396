<template>
  <b-container>
    <b-row>
      <b-col cols="6">
        <b-card
          class="mb-4"
          title="Lookup Member">
          <b-form @submit.prevent="lookupMember">
            <b-form-input
              v-model="query"
              class="mb-2"
              type="text"
              required
              placeholder="Existing Okta Member" />
            <b-btn
              :disabled="!query"
              type="submit"
              variant="primary"
              class="xl-wide">
              Search
            </b-btn>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Add Member">
          <b-form @submit.prevent="createMember">
            <b-row>
              <b-col cols="6">
                <b-form-group label="Email">
                  <b-form-input
                    v-model="member.email"
                    required
                    type="email" />
                </b-form-group>
                <b-form-group label="First Name">
                  <b-form-input
                    v-model="member.firstName"
                    type="text" />
                </b-form-group>
                <b-form-group label="Last Name">
                  <b-form-input
                    v-model="member.lastName"
                    type="text" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <label class="mb-1">Roles</label>
                <b-form-group class="ml-2 text-left">
                  <b-form-checkbox
                    v-model="allRolesSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="roles"
                    aria-controls="roles"
                    @change="selectAllRoles">
                    {{ allRolesSelected ? 'Un-select All' : 'Select All' }}
                  </b-form-checkbox>
                  <b-form-checkbox-group
                    v-model="selectedRoles"
                    :options="roles"
                    stacked
                    class="ml-4" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="d-flex justify-content-between">
                  <b-btn
                    type="reset"
                    variant="outline-primary"
                    class="w-25 xl-wide"
                    @click="$router.go(-1)">
                    Cancel
                  </b-btn>
                  <b-btn
                    :disabled="!readyToCreate"
                    type="submit"
                    variant="primary"
                    class="w-25 xl-wide">
                    Add
                  </b-btn>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        member: {},
        query: null,
        roles: [],
        selectedRoles: [],
        allRolesSelected: false,
        indeterminate: false,
      }
    },
    computed: {
      ...mapGetters([
        'isAdmin',
      ]),
      readyToCreate() {
        return this.member.email && this.selectedRoles.length > 0
      },
    },
    watch: {
      '$route': 'fetchData',
      selectedRoles(newVal) {
        if (newVal.length === 0) {
          this.indeterminate = false
          this.allRolesSelected = false
        } else if (newVal.length === this.roles.length) {
          this.indeterminate = false
          this.allRolesSelected = true
        } else {
          this.indeterminate = true
          this.allRolesSelected = false
        }
      },
    },
    created() {
      this.$loading.showWhile(this.fetchData)
    },
    methods: {
      newMember() {
        return {
          clubId: 'MLB',
          roles: [],
        }
      },
      selectAllRoles(checked) {
          this.selectedRoles = checked ? this.roles.map(role => role.value).slice() : []
      },
      async fetchData() {
        this.member = this.newMember()

        try {
          const allRoles = await this.$api.members.getRoles()
          this.roles = allRoles
            .map(role => {
              return {
                html: `${role.name} &mdash; <small>${role.description}</small>`,
                value: role.name,
              }
            })
        } catch (error) {
          console.error('Unable to retrieve roles.', error)
        }
      },
      async createMember() {
        this.member.login = this.member.email
        this.member.roles = this.selectedRoles

        await this.$api.members.createMember(this.member).then(() => {
          this.$alert.success(`Member ${this.member.login} created successfully`)
          this.$router.push({path: `/members`})
        })
      },
      async lookupMember() {
        const lookupErrorFn = (error) => {
          if (error.response.data.code === 412) {
            this.$alert.warning(`Unable to find "${this.query}" in Okta. User must exist in Okta before allowed access to Club Tools.`)
          } else {
            this.$alert.error(`Unknown error trying to create member -- ${error}`)
          }
          this.member = this.newMember()
        }
        await this.$api.members.getOkta(this.query, lookupErrorFn).then((member) => {
            this.member = {
              login: member.profile.login,
              email: member.profile.login,
              firstName: member.profile.firstName,
              lastName: member.profile.lastName,
              clubId: 'MLB',
              roles: [],
            }
          }
        )
      },
    },
  }
</script>
