<template>
  <b-container class="pb-3">
    <h2 class="my-3">
      Create Retune Job
    </h2>
    <job-form
      @save="onSave"
      @cancel="onCancel" />
  </b-container>
</template>

<script>
import JobForm from "@/components/jobs/JobForm"

export default {
  name: "RetuneJobCreate",
  components: {
    JobForm,
  },
  methods: {
    async onSave(job) {
      await this.$loading.showWhile(async () => {
        await this.$api.jobs.createJob(job)
        this.$alert.success("Retune job created successfully")
        this.$router.push("/retune/jobs/today")
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>

</style>
