<template>
  <b-container>
    <b-row align-h="between">
      <b-col md="auto">
        <tool-header tool-name="Markets" />
      </b-col>
      <b-col>
        <b-btn
          v-if="canRetuneEdit"
          variant="primary"
          to="/retune/markets/create"
          class="float-md-right">
          New Market
        </b-btn>
      </b-col>
    </b-row>
    <b-row
      align-h="end"
      class="mb-3">
      <b-col
        md="4"
        class="mt-2 mt-md-0">
        <b-input-group>
          <b-form-input
            v-model="searchText"
            placeholder="Type to Search" />
          <b-input-group-append>
            <b-btn
              :disabled="!searchText"
              variant="secondary"
              @click="searchText = ''">
              Clear
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <market-table :filter="searchText" />
  </b-container>
</template>

<script>
import MarketTable from "@/components/markets/MarketTable"
import {mapGetters} from "vuex"
import ToolHeader from "@/components/ToolHeader.vue"

export default {
  name: "RetuneMarkets",
  components: {
    ToolHeader,
    MarketTable,
  },
  data: () => ({
    searchText: "",
  }),
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
  }
}
</script>
