<template>
  <b-table
    :items="jobsProviderFn"
    :fields="fields"
    sort-by="startTime"
    no-provider-filtering
    no-provider-sorting
    no-provider-paging
    show-empty>
    <template #empty>
      <div class="font-italic text-muted text-center w-100">
        No jobs are currently active
      </div>
    </template>
    <template #table-busy>
      <div class="font-italic text-muted text-center w-100">
        <b-spinner
          variant="secondary"
          small />
        Loading...
      </div>
    </template>
    <template #cell(marketId)="{ value }">
      {{ getMarketNameForId(value) }}
    </template>
    <template #cell(startTime)="{ value }">
      {{ formatDate(value) }}
    </template>
    <template
      v-if="canRetuneEdit"
      #cell(actions)="{ item }">
      <b-button
        variant="link"
        class="py-0"
        @click="endJob(item)">
        End
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {DateTime} from "luxon"
import {mapGetters} from "vuex"

export default {
  name: "ActiveRetuneJobsTable",
  props: {
    jobsProviderFn: {
      type: Function,
      required: true,
    }
  },
  computed: {
    ...mapGetters([
      "canRetuneEdit"
    ]),
    ...mapGetters("retune", [
      "getMarketNameForId"
    ]),
    fields() {
      const fields = [
        {key: 'marketId', label: 'Market'},
        {key: 'startTime', label: 'Take Received'},
      ]
      if (this.canRetuneEdit) {
        fields.push({key: 'actions', label: 'Actions'})
      }
      return fields
    }
  },
  methods: {
    endJob(job) {
      this.$emit("end-job", job)
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocal().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    }
  }
}
</script>

<style scoped>

</style>
