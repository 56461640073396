<template>
  <b-container
    v-if="block"
    class="pb-3">
    <h2 class="my-3">
      {{ title }}
    </h2>
    <div v-if="block">
      <zip-code-form
        v-if="block.type === 'zip'"
        :value="block"
        @save="onSave"
        @cancel="onCancel" />
      <country-code-form
        v-else-if="block.type === 'country'"
        :value="block"
        @save="onSave"
        @cancel="onCancel" />
      <h2 v-else>
        Unknown block type
      </h2>
    </div>
  </b-container>
</template>

<script>
import CountryCodeForm from "@/components/blocks/CountryCodeForm.vue"
import ZipCodeForm from "@/components/blocks/ZipCodeForm.vue"

export default {
  name: "BlockEdit",
  components: {
    CountryCodeForm,
    ZipCodeForm,
  },
  data: () => ({
    blockId: null,
    block: null
  }),
  computed: {
    title() {
      switch (this.block.type) {
        case "zip":
          return "Edit Zip Code Block"
        case "country":
          return "Edit Country Code Block"
      }

      return "Edit Block"
    },
  },
  async created() {
    await this.$loading.showWhile(async () => {
      const blockId = parseInt(this.$route.params.id)
      const block = await this.$api.zipcodes.getZipCodeBlock(blockId)
      if (block) {
        this.blockId = blockId
        this.block = block
      } else {
        this.$alert.warning(`Zip code or country code block with ID [${blockId}] not found.`)
        this.$router.push("/retune/blocks")
      }
    })
  },
  methods: {
    async onSave(block) {
      await this.$loading.showWhile(async () => {
        block.id = this.blockId
        await this.$api.zipcodes.updateZipCodeBlock(block)
        this.$alert.success("Block updated successfully.")
        this.$router.push("/retune/blocks")
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>

</style>
