<template>
  <b-table
    ref="table"
    striped
    hover
    :items="getZipCodeBlocks"
    :filter="filter"
    :filter-included-fields="filteredFields"
    :fields="fields"
    sort-by="name"
    no-provider-sorting
    no-provider-paging
    no-provider-filtering
    show-empty>
    <template #empty>
      <div class="font-italic text-muted text-center w-100">
        No blocks found
      </div>
    </template>
    <template #emptyfiltered>
      <div class="font-italic text-muted text-center w-100">
        No blocks found matching your search criteria
      </div>
    </template>
    <template #table-busy>
      <div class="font-italic text-muted text-center w-100">
        <b-spinner
          variant="secondary"
          small />
        Loading...
      </div>
    </template>
    <template #cell(type)="{ item }">
      {{ formatBlockType(item.type) }}
    </template>
    <template
      v-if="canRetuneEdit"
      #cell(actions)="{ item }">
      <b-button
        variant="link"
        :to="`/retune/blocks/edit/${item.id}`"
        class="py-0">
        Edit
      </b-button>
      <b-button
        variant="link"
        class="py-0"
        @click="deleteZipCodeBlock(item)">
        Delete
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "BlockTable",
  props: {
    filter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    filteredFields: ["name", "description", "type"]
  }),
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
    fields() {
      const fields = [
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
        {key: 'description', label: 'Description', sortable: false},
        {key: 'type', label: 'Type', sortable: true},
      ]
      if (this.canRetuneEdit) {
        fields.push({key: 'actions', label: 'Actions'})
      }
      return fields
    }
  },
  methods: {
    async getZipCodeBlocks() {
      try {
        return await this.$api.zipcodes.getZipCodeBlocks()
      } catch (e) {
        this.$alert.error("Failed to load blocks. Please try again.")
        return Promise.resolve([])
      }
    },
    async deleteZipCodeBlock(block) {
      if (!this.canRetuneEdit) {
        return
      }

      const modalConfirmed = await this.$confirm.open(`Are you sure you wish to delete ${block.type} code block ${block.name}?`)
      if (!modalConfirmed) {
        return
      }

      await this.$loading.showWhile(async () => {
        try {
          await this.$api.zipcodes.deleteZipCodeBlock(block.id)
          this.$alert.success("Block deleted successfully.")
        } catch (e) {
          this.$alert.error("Failed to delete block. This block may be in use by a market that first needs to be deleted or please try again.")
        }

        this.$refs.table.refresh()
      })
    },
    formatBlockType(type) {
      if (type === "zip") {
        return "Zip Code"
      } else if (type === "country") {
        return "Country"
      }

      return "Unknown"
    }
  }
}
</script>

<style scoped>

</style>
