<template>
  <b-table
    ref="table"
    striped
    hover
    :items="getMarkets"
    :filter="filter"
    :filter-included-fields="filteredFields"
    :fields="fields"
    sort-by="name"
    no-provider-sorting
    no-provider-paging
    no-provider-filtering
    show-empty>
    <template #empty>
      <div class="font-italic text-muted text-center w-100">
        No markets found
      </div>
    </template>
    <template #emptyfiltered>
      <div class="font-italic text-muted text-center w-100">
        No markets found matching your search criteria
      </div>
    </template>
    <template #table-busy>
      <div class="font-italic text-muted text-center w-100">
        <b-spinner
          variant="secondary"
          small />
        Loading...
      </div>
    </template>
    <template #cell(type)="{ value }">
      <div class="text-capitalize">
        {{ formatType(value) }}
      </div>
    </template>
    <template
      v-if="canRetuneEdit"
      #cell(actions)="{ item }">
      <b-button
        variant="link"
        :to="`/retune/markets/edit/${item.id}`"
        class="py-0">
        Edit
      </b-button>
      <b-button
        variant="link"
        class="py-0"
        @click="exportMarket(item)">
        Export
      </b-button>
      <b-button
        variant="link"
        class="py-0"
        @click="deleteMarket(item)">
        Delete
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "MarketTable",
  props: {
    filter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    filteredFields: ["name", "description", "type"]
  }),
  computed: {
    ...mapGetters([
      "canRetuneEdit"
    ]),
    fields() {
      const fields = [
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
        {key: 'description', label: 'Description'},
        {key: 'type', label: 'Type', sortable: true, sortDirection: 'asc'},
      ]
      if (this.canRetuneEdit) {
        fields.push({key: 'actions', label: 'Actions'})
      }
      return fields
    }
  },
  methods: {
    async getMarkets() {
      try {
        const marketsObj = await this.$api.markets.getMarkets()
        return marketsObj.markets
      } catch (error) {
        console.error('Unable to retrieve markets.', error)
      }
    },
    async deleteMarket(market) {
      if (!this.canRetuneEdit) {
        return
      }

      const modalConfirmed = await this.$confirm.open(`Are you sure you wish to delete market ${market.name}?`)
      if (!modalConfirmed) {
        return
      }

      await this.$loading.showWhile(async () => {
        await this.$api.markets.deleteMarket(market.id)
        this.$alert.success("Market deleted successfully")
        this.$refs.table.refresh()
      })
    },
    async exportMarket(market) {
      const codeResults = await this.$api.markets.getMarketZipCodes(market.id)
      if (!codeResults || !codeResults.zipCodes) {
        this.$alert.error("Unable to export zip codes for market")
        return
      }

      const csvData = `ZIP CODE\n${codeResults.zipCodes.join("\n")}`

      const testLink = document.createElement('a')
      testLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
      testLink.target = "_blank"
      testLink.download = `${market.name.replaceAll(/\s+/g, "")}_zip_codes.csv`
      testLink.click()
    },
    formatType(type) {
      switch (type) {
        case "region":
          return "Team Market"
        case "exclusion":
          return "Exclusion"
        case "overlap":
          return "Overlap"
        default:
          return type
      }
    }
  }
}
</script>
