<template>
  <b-form
    novalidate
    :validated="validated"
    @submit.prevent="onSubmit"
    @reset.prevent="onCancel">
    <b-row>
      <b-col cols="6">
        <div>
          <b-form-group
            label-cols="2"
            label="Name"
            label-class="pt-0"
            invalid-feedback="Name is required">
            <b-form-input
              v-model="block.name"
              name="name"
              required />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label="Description"
            label-class="pt-0">
            <b-form-textarea
              v-model="block.description"
              rows="3"
              max-rows="6"
              name="description" />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label="Country Code"
            label-class="pt-0">
            <b-form-input
              v-model="block.country"
              name="country" />
          </b-form-group>
          <b-button
            variant="outline-primary"
            class="ml-3"
            type="submit">
            Save
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-3"
            type="reset">
            Cancel
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label-cols="2"
          label="Zip Codes"
          label-class="pt-0"
          description="A comma-separated list of zip codes (10001,10002,10003)">
          <b-form-textarea
            v-model="zipcodes"
            rows="15"
            max-rows="20"
            name="zipcodes" />
        </b-form-group>
        <b-button
          variant="outline-primary"
          @click="showImportWidget = true">
          Import
        </b-button>
        <b-button
          variant="outline-primary"
          class="ml-3"
          @click="exportZipCodes()">
          Export
        </b-button>
        <b-form-group
          v-if="showImportWidget"
          :state="zipcodeFileInputState"
          class="mt-4"
          label="Upload a list of zipcodes"
          description="The file must contain a single column with the header 'ZIP CODE' and individual zip codes on each line, without commas"
          invalid-feedback="File must contain at least one valid zip code">
          <b-form-file
            ref="zipcodeFileInput"
            v-model="zipcodeFile"
            required
            :state="zipcodeFileInputState"
            :disabled="uploadInProgress"
            accept=".csv, .xls, .xlsx" />
          <template
            v-if="uploadInProgress"
            v-slot:description>
            <b-spinner small />
            Loading file...
          </template>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Papa from "papaparse"

export default {
  name: "ZipCodeForm",
  props: {
    value: {
      type: Object,
      default: () => ({
        name: "",
        description: "",
        country: "US",
        codes: [],
      })
    }
  },
  data: () => ({
    validated: false,
    importResults: null,
    showImportWidget: false,
    zipcodeFile: null,
    uploadInProgress: false,
    block: {
      name: "",
      description: "",
      country: "US",
      codes: [],
    },
    zipcodes: "",
  }),
  computed: {
    zipcodeFileInputState() {
      if (!this.zipcodeFile || this.uploadInProgress) {
        return null
      }
      return this.zipcodes.length > 0
    },
  },
  watch: {
    zipcodeFile(newVal) {
      if (!newVal) {
        return
      }
      this.$loading.show()
      this.block.name = newVal.name
      if (newVal.name.includes(".csv")) {
        const parts = newVal.name.split(".csv")
        this.block.name = parts[0]
      }
      this.block.description = "CSV Import"
      this.uploadInProgress = true
      Papa.parse(newVal, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          this.importResults = results
          this.uploadInProgress = false
          this.showImportWidget = false
          this.zipcodes = results.data.map(row => row["ZIP CODE"]).join(",")
          this.$loading.hide()
        },
        error: () => this.uploadInProgress = false,
      })
    },
  },
  created() {
    this.block = this.value
    if (this.block.codes) {
      this.zipcodes = this.block.codes.join(",")
    }
  },
  methods: {
    async exportZipCodes() {
      if (this.block.codes.length < 1) {
        alert("No zip codes to export.")
        return
      }
      let csvData = "ZIP CODE\n"
      this.block.codes.forEach((code) => {
        csvData += `${code}\n`
      })
      let testLink = document.createElement('a')
      testLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
      testLink.target = "_blank"
      let filename = "exported_zipcdes"
      if (this.block.name.trim() !== "") {
        filename = this.block.name.trim()
      }
      testLink.download = `${filename}.csv`
      testLink.click()
    },
    onSubmit(evt) {
      this.validated = true

      const form = evt.target
      if (!form.checkValidity()) {
        this.$alert.warning("Zip code block contains one or more errors. Please fix these errors before saving.")
        return
      }

      // Make sure we don't have any duplicates in the zip code array.
      this.block.codes = [...new Set(this.zipcodes.split(","))]

      // Ensure a default country code of "US" is set if none is provided.
      if (this.block.country.trim() === "") {
        this.block.country = "US"
      }

      this.$emit("save", this.block)
    },
    onCancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>

</style>
