<template>
  <b-container class="pb-3">
    <h2 class="my-3">
      Create Market
    </h2>
    <market-form
      @save="onSave"
      @cancel="onCancel" />
  </b-container>
</template>

<script>
import MarketForm from "@/components/markets/MarketForm"
import {mapActions} from "vuex"

export default {
  name: "MarketCreate",
  components: {
    MarketForm,
  },
  methods: {
    ...mapActions("retune", [
      "loadMarkets",
    ]),
    async onSave(marketRequest) {
      await this.$loading.showWhile(async () => {
        await this.$api.markets.createMarket(marketRequest)
        await this.loadMarkets()
        this.$alert.success("Market created successfully")
        this.$router.push("/retune/markets")
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>

</style>
