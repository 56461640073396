function getBlockType(block) {
  if (block.codes && block.codes.length) {
    return "zip"
  } else if (block.countries && block.countries.length) {
    return "country"
  }

  return "unknown"
}


export default $client => ({
  async getZipCodeBlocks() {
    const res = await $client.execute("get", "/api/retune/v1/zipcode/blocks", null, () =>
      console.warn("Unable to get zip code blocks"))

    if (!res || !res.zipCodeBlocks) {
      // Zip code blocks can be null, so if they don't exist, just return an empty array.
      return []
    }

    // Extract the blocks and add a type property to each one.
    return res.zipCodeBlocks.map(block => ({
      ...block,
      type: getBlockType(block),
    }))
  },
  async getZipCodeBlock(blockId) {
    const block = await $client.execute("get", `/api/retune/v1/zipcode/block/${blockId}`, null, () =>
      console.warn(`Unable to get zip code block with id: ${blockId}`))

    // Set a type property on the block as determined by the fields it contains.
    return {
      ...block,
      type: getBlockType(block),
    }
  },
  createZipCodeBlock(zipCodeBlock) {
    return $client.execute("post", "/api/retune/v1/zipcode/block", zipCodeBlock, () =>
      console.warn("Unable to create zip code block"),
    )
  },
  updateZipCodeBlock(zipCodeBlock) {
    return $client.execute("put", `/api/retune/v1/zipcode/block/${zipCodeBlock.id}`, zipCodeBlock, () =>
      console.warn(`Unable to update zip code block with id: ${zipCodeBlock.id}`),
    )
  },
  deleteZipCodeBlock(blockId) {
    return $client.execute("delete", `/api/retune/v1/zipcode/block/${blockId}`, null, () =>
      console.warn(`Unable to delete zip code block with id: ${blockId}`),
    )
  },
})
