<template>
  <div>
    <h5>Retune Market 1 (alt channel)</h5>
    <active-retune-jobs-table
      :jobs-provider-fn="getMarket1Jobs"
      @end-job="endJob" />
    <h5>Retune Market 2 (slate)</h5>
    <active-retune-jobs-table
      :jobs-provider-fn="getMarket2Jobs"
      @end-job="endJob" />
  </div>
</template>

<script>
import ActiveRetuneJobsTable from "@/components/jobs/ActiveRetuneJobsTable"

export default {
  name: "ActiveRetuneJobs",
  components: {
    ActiveRetuneJobsTable,
  },
  props: {
    jobsProviderFn: {
      type: Function,
      required: true,
    }
  },
  methods: {
    async getMarket1Jobs() {
      const allActiveJobs = await this.jobsProviderFn()
      return allActiveJobs.filter(it => it.type === "B")
    },
    async getMarket2Jobs() {
      const allActiveJobs = await this.jobsProviderFn()
      return allActiveJobs.filter(it => it.type === "C")
    },
    endJob(job) {
      this.$emit("end-job", job)
    }
  }
}
</script>

<style scoped>

</style>
