
export default $client => ({
  getMarkets() {
    return $client.execute("get", "/api/retune/v1/market/", null, () =>
      console.warn("Unable to get markets"),
    )
  },
  getMarket(marketId) {
    return $client.execute("get", `/api/retune/v1/market/${marketId}`, null, () =>
      console.warn(`Unable to get market with id: ${marketId}`),
    )
  },
  getMarketZipCodes(marketId) {
    return $client.execute("get", `/api/retune/v1/market/${marketId}/zipcode`, null, () =>
      console.warn(`Unable to get zip codes for market with id: ${marketId}`),
    )
  },
  createMarket(marketRequest) {
    return $client.execute("post", "/api/retune/v1/market", marketRequest, () =>
      console.warn("Unable to create market"),
    )
  },
  updateMarket(marketRequest) {
    return $client.execute("put", `/api/retune/v1/market/${marketRequest.id}`, marketRequest, () =>
      console.warn(`Unable to update market with id: ${marketRequest.id}`),
    )
  },
  deleteMarket(marketId) {
    return $client.execute("delete", `/api/retune/v1/market/${marketId}`, null, () =>
      console.warn(`Unable to delete market with id: ${marketId}`),
    )
  },
})
