<template>
  <div>
    <b-alert variant="info">
      Authenticating...
    </b-alert>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "Sso",
  data() {
    return {}
  },
  async created() {
    await this.handleSSO()
  },
  methods: {
    ...mapActions(["handleSSO"]),
  },
}
</script>
