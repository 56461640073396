<template>
  <div>
    <b-navbar
      variant="light"
      class="mb-3">
      <b-navbar-nav>
        <b-nav-item
          to="/retune/jobs"
          active-class="active">
          Jobs
        </b-nav-item>
        <b-nav-item
          to="/retune/markets"
          active-class="active">
          Markets
        </b-nav-item>
        <b-nav-item
          to="/retune/blocks"
          active-class="active">
          Blocks
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "Retune",
  async created() {
    await this.loadMarkets()
  },
  methods: {
    ...mapActions("retune", [
      "loadMarkets",
    ])
  }
}
</script>

<style scoped>

</style>
