<template>
  <b-container
    :fluid="fluid"
    class="px-0">
    <b-row>
      <b-col
        v-if="toolName"
        md="auto"
        class="tool-name">
        {{ toolName }}
      </b-col>
      <b-col
        v-if="hasHeaderSlot"
        class="mt-1 pt-2">
        <slot />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ToolHeader",
  props: {
    toolName: {
      type: String,
      default: "",
    },
    fluid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.default
    },
  },
}
</script>

<style scoped>
.tool-name {
  font-size: 32px;
  color: #333333;
}
</style>
