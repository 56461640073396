<template>
  <b-container class="pb-3">
    <h2 class="my-3">
      {{ title }}
    </h2>
    <zip-code-form
      v-if="isZipCodeBlock"
      @save="onSave"
      @cancel="onCancel" />
    <country-code-form
      v-else-if="isCountryCodeBlock"
      @save="onSave"
      @cancel="onCancel" />
    <h2 v-else>
      Unknown block type
    </h2>
  </b-container>
</template>

<script>
import CountryCodeForm from "@/components/blocks/CountryCodeForm.vue"
import ZipCodeForm from "@/components/blocks/ZipCodeForm.vue"

export default {
  name: "BlockCreate",
  components: {
    CountryCodeForm,
    ZipCodeForm,
  },
  data() {
    return {
      type: "zip",
    }
  },
  computed: {
    isZipCodeBlock() {
      return this.type === "zip"
    },
    isCountryCodeBlock() {
      return this.type === "country"
    },
    title() {
      switch (this.type) {
        case "zip":
          return "Create Zip Code Block"
        case "country":
          return "Create Country Code Block"
      }

      return "Create Block"
    },
  },
  created() {
    this.type = this.$route.query.type || "zip"
  },
  methods: {
    async onSave(zipCodeBlock) {
      await this.$loading.showWhile(async () => {
        await this.$api.zipcodes.createZipCodeBlock(zipCodeBlock)
        this.$alert.success("Zip code block created successfully")
        this.$router.push("/retune/blocks")
      })
    },
    onCancel() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>

</style>
