<template>
  <div>
    <h4 class="w-100 text-center my-3">
      Retune Job History
    </h4>
    <retune-job-history-table />
  </div>
</template>

<script>
import RetuneJobHistoryTable from "@/components/jobs/RetuneJobHistoryTable"

export default {
  name: "RetuneJobHistory",
  components: {
    RetuneJobHistoryTable,
  },
}
</script>

<style scoped>

</style>
