import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin, LayoutPlugin, ModalPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import API from "@/plugins/api"
import Confirm from "@/plugins/confirm"
import Loading from "@/plugins/loading"
import {OktaAuth} from '@okta/okta-auth-js'
import OktaVue from "@okta/okta-vue"
import 'vue-search-select/dist/VueSearchSelect.css'
import AsyncComputed from 'vue-async-computed'
import 'vue-search-select/dist/VueSearchSelect.css'

Vue.config.productionTip = false

const oktaAuth = new OktaAuth({
  url: process.env.VUE_APP_OKTA_URL,
  issuer: process.env.VUE_APP_OKTA_ISSUER,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: false,
  tokenManager: {
    // Emit expired event 2 minutes before expiration
    // Tokens accessed with tokenManager.get() will auto-renew within 2 minutes of expiration
    expireEarlySeconds: 120,
  },
})
Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    router.push("/login")
  },
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(AsyncComputed)
Vue.use(API)
Vue.use(Confirm)
Vue.use(Loading)

// define an event bus for sending/receiving messages
Vue.prototype.$bus = new Vue()

// Define a way to easily send alert messages to the event bus
Vue.prototype.$alert = {
  success: msg =>
    Vue.prototype.$bus.$emit("send-alert", { text: msg, variant: "success" }),
  warning: msg =>
    Vue.prototype.$bus.$emit("send-alert", { text: msg, variant: "warning" }),
  error: msg =>
    Vue.prototype.$bus.$emit("send-alert", { text: msg, variant: "danger" }),
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
