import Vue from "vue"

export default {
  actions: {
    getRoles ({ rootGetters }) {
      if (rootGetters.isAdmin) {
        return Vue.prototype.$api.members.getRoles()
      } else {
        // disable roles that are internal
        return Vue.prototype.$api.members.getRoles().then(roles => {
          roles.forEach(r => (r.disabled = r.internal))
          return roles
        })
      }
    },
  },
}
