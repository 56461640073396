import Vue from "vue"
import store from "@/store"
import axios from "axios"
import Members from "./members"
import ZipCodes from "./zipcodes"
import Markets from "./markets"
import Jobs from "./jobs"
import Feeds from "./feeds"
import Esni from "./esni"

const router = () => import("@/router")

const client = axios.create({
  json: true,
})

const defaultErrorFn = async error => {
  const logout = async () => {
    console.warn("User session has expired. Logging out.")
    await store.dispatch("logout")
    router.push("/login")
  }

  if (error.response.status === 403) {
    console.info("Attempting to refresh Okta auth...")
    const token = await Vue.prototype.$auth.getUser().catch(async () => {
      await logout()
    })
    if (!token) {
      await logout()
    }
    return
  }
  let errorDetails = ""
  if (error.response && error.response.data) {
    errorDetails = ` -- ${error.response.data.message}`
  }
  Vue.prototype.$alert.error(
    `Unable to complete request -- ${error}${errorDetails}`,
  )
}

const apiClient = {
  async authHeader() {
    let token = await Vue.prototype.$auth.getAccessToken()
    return {
      Authorization: `Bearer ${token}`,
    }
  },
  async execute(method, resource, data, overrideErrorFn) {
    // inject the idToken for each request
    let authHeader = await this.authHeader()
    authHeader["X-Member-JWT"] = store.getters.jwt

    // send along the okta info for development because we don't have an auth proxy
    if (process.env.NODE_ENV === "development") {
      authHeader["X-OKTA-ID"] = store.getters.login
      authHeader["X-OKTA-EMAIL"] = store.getters.login
    }

    return client({
      method,
      url: resource,
      data,
      headers: authHeader,
    })
      .then(resp => {
        return resp.data
      })
      .catch(error => {
        overrideErrorFn ? overrideErrorFn(error) : defaultErrorFn(error)
        throw error
      })
  },
  executeExternal(method, resource, data, overrideErrorFn) {
    return client({
      method,
      url: resource,
      data,
    })
      .then(resp => {
        return resp.data
      })
      .catch(error => {
        overrideErrorFn ? overrideErrorFn(error) : defaultErrorFn(error)
        throw error
      })
  },
  callCloudFn(fn, method = "get", data = null) {
    return this.executeExternal(
      method,
      `https://${process.env.VUE_APP_GCP_REGION_PROJECT_ID}.cloudfunctions.net/${fn}`,
      data,
    )
  },
}

export default {
  client: apiClient,
  // Add all API modules here to make them available project-wide
  members: Members(apiClient),
  zipcodes: ZipCodes(apiClient),
  markets: Markets(apiClient),
  jobs: Jobs(apiClient),
  feeds: Feeds(apiClient),
  esni: Esni(apiClient),
}
