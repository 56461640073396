import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routes"
import store from "@/store"
import RouterAuthGuard from "@/router/authguard"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const navigationGuard = new RouterAuthGuard(store)

router.beforeEach((to, from, next) =>
  navigationGuard.onAuthRequired(to, from, next),
)

export default router
