<template>
  <b-container>
    <b-row align-h="between">
      <b-col md="auto">
        <tool-header tool-name="Retune Jobs" />
      </b-col>
      <b-col>
        <b-btn
          v-if="canRetuneEdit"
          variant="primary"
          to="/retune/jobs/create"
          class="float-md-right mb-2 mb-md-0">
          New Job
        </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-nav tabs>
          <b-nav-item
            to="/retune/jobs/today"
            exact
            exact-active-class="active">
            Today
          </b-nav-item>
          <b-nav-item
            to="/retune/jobs/upcoming"
            exact
            exact-active-class="active">
            Upcoming
          </b-nav-item>
          <b-nav-item
            to="/retune/jobs/history"
            exact
            exact-active-class="active">
            History
          </b-nav-item>
        </b-nav>
        <router-view class="mt-3" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import {mapGetters} from "vuex"
import ToolHeader from "@/components/ToolHeader.vue"

export default {
  name: "RetuneJobs",
  components: {ToolHeader},
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
  }
}
</script>

<style scoped>

</style>
