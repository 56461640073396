<template>
  <div>
    <b-alert variant="info">
      Authenticating...
    </b-alert>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "Auth",
  async created() {
    try {
      await this.handleAuthentication()
    } catch (error) {
      // The member info was not found in the database so show a hopefully helpful error page
      console.warn("Unable to authenticate", error)
      await this.$router.push({
        path: "/error",
        query: {type: "account"},
      })
    }
  },
  methods: {
    ...mapActions([
      "handleAuthentication",
    ]),
  },
}
</script>
