export default $client => ({
  getFeeds(gamePk) {
    return $client.executeExternal("get", `${process.env.VUE_APP_ESNI_BASE_URL}/api/v1/esni/entitlementSearch/feeds?gamePks=${gamePk}&includeFeedXref=true`)
  },
  getViewingPolicies(feedIds) {
    return $client.executeExternal("get", `${process.env.VUE_APP_ESNI_BASE_URL}/api/v1/esni/entitlementSearch/viewingpolicy?feedIds=${feedIds}`)
  },
  getAudienceByIds(audienceIds) {
    return $client.executeExternal("get", `${process.env.VUE_APP_ESNI_BASE_URL}/api/v1/esni/entitlementSearch/audience?audienceIds=${audienceIds}`)
  }
})
