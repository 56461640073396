import Vue from "vue"
import jwx from "jsonwebtoken"

export default {
  state: {
    jwt: null,
  },
  getters: {
    authenticated: state => state.jwt !== null,
    roles: state => {
      try {
        return jwx.decode(state.jwt).roles
      } catch {
        return null
      }
    },
    jwt: state => state.jwt,
    login: state => {
      try {
        return jwx.decode(state.jwt).login
      } catch {
        return null
      }
    },
  },
  actions: {
    async logout({ commit }) {
      await Vue.prototype.$auth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
        // Temporarily prevent access token revoking to work around a bug:
        // https://github.com/okta/okta-auth-js/issues/496
        revokeAccessToken: false,
      })
      commit("logout")
    },
    async handleSSO() {
      await Vue.prototype.$auth.signInWithRedirect({ originalUri: "/" })
    },
    async handleAuthentication({ commit }) {
      // store the okta id and access tokens in local storage
      await Vue.prototype.$auth.handleLoginRedirect()

      // try to extract the jwt from the API
      const authResult = await Vue.prototype.$api.members.memberInfo()

      commit("authenticated", { jwt: authResult.memberJwt })
    },
  },
  mutations: {
    authenticated(state, data) {
      state.jwt = data.jwt
    },
    logout(state) {
      state.jwt = null
    },
  },
}
