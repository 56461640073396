import Vue from "vue"

export default {
  namespaced: true,
  state: {
    markets: []
  },
  getters: {
    getMarketNameForId: state => id => {
      const market = state.markets.find(it => it.id === id)
      return market && market.name ? market.name : "Unknown"
    }
  },
  mutations: {
    setMarkets(state, markets) {
      state.markets = markets || []
    }
  },
  actions: {
    async loadMarkets({ commit }) {
      const markets = await Vue.prototype.$api.markets.getMarkets()
      commit("setMarkets", markets.markets)
    }
  }
}
