var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"items":_vm.jobsProviderFn,"fields":_vm.fields,"no-provider-filtering":"","no-provider-sorting":"","no-provider-paging":"","sort-by":"estimatedStartTime","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"font-italic text-muted text-center w-100"},[_vm._v(" No jobs are currently scheduled ")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"font-italic text-muted text-center w-100"},[_c('b-spinner',{attrs:{"variant":"secondary","small":""}}),_vm._v(" Loading... ")],1)]},proxy:true},{key:"cell(estimatedStartTime)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"cell(marketId)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getMarketNameForId(value))+" ")]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [(value === 'B')?[_vm._v(" Market 1 (alt channel) ")]:(value === 'C')?[_vm._v(" Market 2 (slate) ")]:[_vm._v(" "+_vm._s(value)+" ")]]}},(_vm.canRetuneEdit)?{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(_vm.enableTake)?_c('b-button',{staticClass:"py-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.activateJob(item)}}},[_vm._v(" Take ")]):_vm._e(),_c('b-button',{staticClass:"py-0",attrs:{"variant":"link"},on:{"click":function($event){return _vm.endJob(item)}}},[_vm._v(" Cancel ")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }