<template>
  <b-container>
    <b-row>
      <b-col cols="5">
        <b-card
          v-if="selectedMember"
          header="Member Details">
          <dl class="row">
            <dt class="text-right col-sm-4">
              Username:
            </dt>
            <dd class="col-sm-8">
              {{ selectedMember.login }}
            </dd>
            <dt class="text-right col-sm-4">
              First Name:
            </dt>
            <dd class="col-sm-8">
              {{ selectedMember.firstName }}
            </dd>
            <dt class="text-right col-sm-4">
              Last Name:
            </dt>
            <dd class="col-sm-8">
              {{ selectedMember.lastName }}
            </dd>
            <dt class="text-right col-sm-4">
              Club:
            </dt>
            <dd class="col-sm-8">
              {{ selectedMember.clubId }}&nbsp;
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col cols="7">
        <b-card
          v-if="roles"
          header="Roles">
          <b-form
            @submit.prevent="updateRoles"
            @reset.prevent="navigateToMembersList">
            <b-form-group class="text-left">
              <b-form-checkbox-group
                v-model="selectedMemberRoles"
                :options="roles"
                stacked />
            </b-form-group>
            <b-btn
              type="reset"
              variant="outline-primary"
              class="xl-wide">
              Cancel
            </b-btn>
            <b-btn
              type="submit"
              variant="outline-primary"
              class="float-right xl-wide">
              Submit
            </b-btn>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        login: null,
        selectedMember: null,
        originalMemberRoles: null,
        selectedMemberRoles: null,
        currentRoles: null,
        availableRoles: null,
        roles: [],
      }
    },
    watch: {
      '$route': 'onRouteChange',
    },
    async created() {
      this.login = this.$route.params.login
      await this.$loading.showWhile(this.fetchData)
    },
    methods: {
      async updateRoles() {
        let login = this.selectedMember.login

        // add the missing roles
        let toAdd = this.selectedMemberRoles.filter(p => !this.originalMemberRoles.includes(p))
        console.log(`Adding the following roles -- ${toAdd.join(', ')}`)
        let additionsFn = toAdd.map(p => this.$api.members.addRole(login, p))

        // remove the unselected roles
        let toRemove = this.originalMemberRoles.filter(p => !this.selectedMemberRoles.includes(p))
        console.log(`Removing the following roles -- ${toRemove.join(', ')}`)
        let removalsFn = toRemove.map(p => this.$api.members.removeRole(login, p))

        axios.all(additionsFn.concat(removalsFn)).then(results => {
          if (results.length > 0) {
            this.$alert.success(`Successfully updated roles for ${login}`)
          }
        })

        this.navigateToMembersList()
      },
      navigateToMembersList() {
        this.$router.push({path: '/members'})
      },
      async onRouteChange(route) {
        this.login = route.params.login
        await this.$loading.showWhile(this.fetchData)
      },
      async fetchData() {
        this.selectedMember = await this.$api.members.getMember(this.login)

        let allRoles = await this.$api.members.getRoles()
        this.selectedMemberRoles = await this.$api.members.getMemberRoles(this.login)
        this.originalMemberRoles = this.selectedMemberRoles
        this.availableRoles = allRoles.filter(p => !this.selectedMemberRoles.includes(p.name))
        this.currentRoles = allRoles.filter(p => this.selectedMemberRoles.includes(p.name))
        this.roles = allRoles.map(function(p) {
          return {html: `${p.name} &mdash; <small>${p.description}</small>`, value: p.name}
        })
      },
    },
  }
</script>
