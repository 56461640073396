import Vue from 'vue'

export default $client => ({
  memberInfo() {
    return $client.execute("get", "/api/user/v1/memberinfo", null, () =>
      console.warn("Unable to find current member info"),
    )
  },
  getRoles() {
    return $client.execute("get", `/api/user/v1/roles`).then(
      result => result.roles,
    )
  },
  getMembers() {
    return $client.execute("get", "/api/user/v1/members").then(
      result => result.members,
    )
  },
  getMember(login, errorFn) {
    return $client.execute("get", `/api/user/v1/members/${login}`, null, errorFn)
  },
  getOkta(login, errorFn) {
    return $client.execute("get", `/api/user/v1/okta/${login}`, null, errorFn)
  },
  getMemberRoles(login) {
    return $client.execute("get", `/api/user/v1/members/${login}/roles`)
  },
  createMember(memberInfo) {
    return $client.execute("post", "/api/user/v1/members", memberInfo)
  },
  deleteMember(login) {
    return $client.execute("delete", `/api/user/v1/members/${login}`).then(() => {
      Vue.prototype.$alert.success(`Member ${login} deleted successfully`)
    })
  },
  addRole(login, role) {
    return $client.execute(
      "put",
      `/api/user/v1/members/${login}/roles/${role}`,
      null,
    ).then(() => {
      Vue.prototype.$alert.success(`Role [${role}] successfully added`)
    })
  },
  removeRole(login, role) {
    return $client.execute(
      "delete",
      `/api/user/v1/members/${login}/roles/${role}`,
      null,
    ).then(() => {
      Vue.prototype.$alert.success(`Role [${role}] successfully removed`)
    })
  },
})
