<template>
  <b-navbar
    toggleable="md"
    type="dark">
    <b-navbar-toggle target="nav_collapse" />
    <b-img
      class="mr-2"
      width="56"
      height="30"
      src="/mlb-logo.svg"
      alt="MLB" />
    <b-navbar-brand to="/">
      MLB MAST Tools
    </b-navbar-brand>
    <b-collapse
      id="nav_collapse"
      is-nav>
      <b-navbar-nav v-if="authenticated">
        <b-nav-item
          to="/"
          exact>
          Dashboard
        </b-nav-item>
        <b-nav-item
          v-if="canMembersView"
          to="/members">
          Members
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-if="!authenticated"
          to="/login">
          Login
        </b-nav-item>
        <b-nav-item
          v-else
          href="#"
          @click.prevent="onLogout">
          Logout
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "Navbar",
  computed: {
    ...mapGetters([
      "authenticated",
      "canMembersView",
    ]),
  },
  methods: {
    async onLogout() {
      await this.$loading.showWhile(async () => {
        await this.logout()
          .catch(error => {
            if (error.errorCode === "E0000007") {
              console.log("Session expired, redirecting back to login")
            } else {
              console.log("Unable to logout successfully")
              console.log(error)
            }
            this.$router.push("/login")
          })
      })
    },
    ...mapActions([
      "logout",
    ]),
  },
}
</script>

<style scoped>
.navbar {
  background-color: #041E42;
}
</style>
