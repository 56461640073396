<template>
  <b-modal
    id="confirm"
    ref="confirm"
    :hide-header="true"
    :hide-header-close="true"
    :ok-title="options.okTitle"
    :cancel-title="options.cancelTitle"
    @hide="onHide"
    @ok="onOk"
    @cancel="onCancel">
    <b-container class="confirm-container">
      <b-row>
        <b-col
          class="confirm_image"
          cols="1">
          <span>
            <b-icon-exclamation-triangle
              width="75"
              height="75"
              class="confirm-icon" />
          </span>
        </b-col>
        <b-col cols="1" />
        <b-col
          class="confirm-text"
          cols="10">
          <span style="white-space: pre-line">{{ message }}</span>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      show: false,
      message: null,
      resolve: null,
      defaultOptions: {
        okTitle: "Yes",
        cancelTitle: "Cancel",
      },
      options: null,
    }
  },
  methods: {
    open(message, options) {
      this.options = {
        okTitle: "Yes",
        cancelTitle: "Cancel",
      }
      if (options) {
        this.options = Object.assign(this.options, options)
      }
      if (!this.$parent) {
        this.$mount()
        document.body.className = document.body.className.replace(
          "modal-open",
          "",
        )
        document.body.appendChild(this.$el)
      }
      return new Promise(resolve => {
        this.message = message
        this.resolve = resolve
        this.$refs.confirm.show()
      })
    },
    onOk() {
      this.resolve(true)
    },
    onCancel() {
      this.resolve(false)
    },
    onHide(evt) {
      if (evt.trigger === "esc" || evt.trigger === "header-close") {
        this.resolve(false)
      }
    },
  },
}
</script>

<style scoped>
.confirm-icon {
  padding-right: 2rem;
  color: #fad100;
}

.confirm-container {
  display: flex;
  background: #fff;
}

.confirm-text {
  flex: 1 0 50%;
  padding: 10px;
}

.confirm-image {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
}
</style>
