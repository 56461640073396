<template>
  <b-table
    :items="jobsProviderFn"
    :fields="fields"
    no-provider-filtering
    no-provider-sorting
    no-provider-paging
    sort-by="estimatedStartTime"
    show-empty>
    <template #empty>
      <div class="font-italic text-muted text-center w-100">
        No jobs are currently scheduled
      </div>
    </template>
    <template #table-busy>
      <div class="font-italic text-muted text-center w-100">
        <b-spinner
          variant="secondary"
          small />
        Loading...
      </div>
    </template>
    <template #cell(estimatedStartTime)="{ value }">
      {{ formatDate(value) }}
    </template>
    <template #cell(marketId)="{ value }">
      {{ getMarketNameForId(value) }}
    </template>
    <template #cell(type)="{ value }">
      <template v-if="value === 'B'">
        Market 1 (alt channel)
      </template>
      <template v-else-if="value === 'C'">
        Market 2 (slate)
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
    <template
      v-if="canRetuneEdit"
      #cell(actions)="{ item }">
      <b-button
        v-if="enableTake"
        variant="link"
        class="py-0"
        @click="activateJob(item)">
        Take
      </b-button>
      <b-button
        variant="link"
        class="py-0"
        @click="endJob(item)">
        Cancel
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {DateTime} from "luxon"
import {mapGetters} from "vuex"

export default {
  name: "ScheduledRetuneJobsTable",
  props: {
    jobsProviderFn: {
      type: Function,
      required: true,
    },
    enableTake: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "canRetuneEdit",
    ]),
    ...mapGetters("retune", [
      "getMarketNameForId"
    ]),
    fields() {
      const fields = [
        {key: 'estimatedStartTime', label: 'Estimated Start Time', sortable: true},
        {key: 'marketId', label: 'Retune Market'},
        {key: 'type', label: 'Type'},
      ]
      if (this.canRetuneEdit) {
        fields.push({key: 'actions', label: 'Actions'})
      }
      return fields
    }
  },
  methods: {
    activateJob(job) {
      this.$emit("activate-job", job)
    },
    endJob(job) {
      this.$emit("end-job", job)
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocal().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    }
  }
}
</script>

<style scoped>

</style>
